<script setup>
import { ref, } from 'vue'
import { useUserStore } from '@/stores/user'
const store = useUserStore()
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

let email = ref('');
let password = ref('');
let isSubmitting = ref(false);
let validated = ref(false)
let invalid = ref(false)

let failed = ref(false)
let message = ref('')

if (route.query.status == "validated") {
  validated.value = true
}
if (route.query.status == "invalid") {
  invalid.value = true
}


async function login() {
    isSubmitting.value = true

    validated.value = false
    invalid.value = false
    // VALIDATE!!! username/password 
    // const { data } = await axios.get(context.env.apiUrl + "/api/services"); 
    // should send a verification email ---- 2FA??
    // let {data} = await AuthService.login(this.email, this.password)
    let result = await store.login(email.value, password.value)

    isSubmitting.value = false
    
    if(result.status) {
        email.value = "",
        password.value = ""
        router.push("/");        
    } else {
        failed.value = true
        message.value = result.message
    }
}

</script>

<template>
    <div class="wrapper">
        <div class="container fill align-items-center">

            <div class="col-md-6">

                <div id="login-container">
                    <h3 class="subheader mb-4">Threat Intelligence at your fingertips</h3>
                    <div class="alert alert-success" v-if="validated" role="alert">
                      Account validated! 
                    </div>
                    <div class="alert alert-warning" v-else-if="invalid" role="alert">
                      Invalid key - please follow the link in the email
                    </div>

                    <h1 class="display-4 header mb-4">Login to Webscout</h1>
                    <p class="subsubheader ">Don't have an account? <router-link to="/signup" class="ws-gradient-text"><strong>Sign up</strong></router-link> </p>
                    <p v-if="failed" class="ms-1 danger mb-4">{{message}}</p>
                    <form action="#" @submit.prevent="login()" class="form-floating mb-4">
                        <div class="form-floating mb-4 col-md-7">
                            <input type="email" v-model="email" class="form-control dark" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Email address</label>
                        </div>
                        <div class="form-floating mb-5 col-md-7">
                            <input type="password" v-model="password" class="form-control dark" id="floatingPassword" placeholder="Password">
                            <label for="floatingPassword">Password</label>
                        </div>
                        
                        <button id="submit-btn" v-if="!isSubmitting" type="submit" class="btn btn-primary ms-2">Login</button>
                        <button id="submit-btn" v-else disabled type="submit" class="btn btn-primary ms-2">logging in...</button>
                    </form>
                </div>

            </div>

        </div>


    </div>

</template>

<style scoped>

.wrapper {
    background-image: url('~@/assets/split-map.svg');
    background-repeat: no-repeat;
    background-position: 0;
    background-position-x: 100%;
    background-size: 55%;
}

.danger {
    color: #ffb0b0;
    font-size: 1rem;
}

.header {
    font-weight: 900;
}

.subheader {
    font-weight: 500;
    color: #536173;
    font-family: Sansation;
}

.subsubheader {
    color: #536173;
    font-size: 1.1rem;
    font-family: Sansation; 
}

#submit-btn {
    padding: .8rem 5rem; 
    background-color:#7357D2;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    border-radius: 30px;
}

#submit-btn:hover {
    background-color: #7F62E3;
}


</style>
