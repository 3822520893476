<script setup>
import { ref, computed, onMounted, defineProps } from 'vue'
const utils = require('../lib/util'); 
import { useRouter } from 'vue-router'
const router = useRouter()

const props = defineProps({
  search_value: {
    type: String,
    default: ''
  }
})


const scan_input = ref(props.search_value)
const display_list = ref(false)

//const valid_domain = computed(() => utils.is_domain(scan_input.value) || utils.is_url(scan_input.value))
const valid_domain = computed(() => utils.is_domain(scan_input.value))

const is_scanning = ref(false)

async function scan_trigger() {
    if (!valid_domain.value) return false; 

    is_scanning.value = true; 
    // let status = await productStore.startWebscoutPublic(domain.domain_id)
    router.push("/lookup/"+scan_input.value)

    is_scanning.value = false; 
    // scan_input.value = ""
  // if (status) {
    //     // scan created
    //     router.push("/reports")
    // } else {
    //     alert("failed to create scan");
    //     is_scanning.value = false;
    // }
}

onMounted(() => {
  scan_input.value = props.search_value
})
</script>

<template>
    <div class="select">
      <form @submit.prevent="scan_trigger">
        <div class="cont vertically-centered" :class="{'contOpen': display_list}">
          <font-awesome-icon style="font-size: 1.1rem; margin-left: 2rem; color: #9ea5b0;" :icon="['fas', 'magnifying-glass']"/>
          <div class="form-floating cont-inner">
                <input v-model="scan_input" :disabled="is_scanning" type="text" class="form-control" placeholder="Domain to lookup">
                <label for="floatingInput">Domain to lookup</label>
          </div>
          <div class="cont-extras">
              <slot name="features"></slot>
              <slot name="trigger" v-if="!is_scanning && valid_domain"></slot>
              <slot name="invalidtrigger" v-else-if="!is_scanning && !valid_domain"></slot>
              <slot v-else-if="is_scanning" name="processing"></slot>
          </div>
        </div>

        <div v-if="display_list" class="itemlist">
          <div class="item" v-for="item in display_list" :key="item">
            <slot v-bind="item"></slot>
          </div>
        </div>
      </form>
    </div>
</template>

<style scoped>
  .cont-extras {
    align-items: center;
    display: flex;
    padding-right: 2rem;
  }

#btn-trigger {
      padding: .6rem 1.4rem; 
      background-color:#7357D2;
      font-weight: bold;
      outline:none;
      box-shadow: none;
      border:none;
      color: white;
      z-index: 5;
      border-radius: 10px;
      transition: all .3s ease;
  }

  #btn-trigger:hover {
      background-color: #7F62E3;
  }

  .select .cont {
    background-color: white;
    display: flex;
    border-radius: 15px;
    /* flex-direction: column; */
  }

  .cont-inner {
    flex:1;
    align-items:center;
    display: flex;
    height: 4rem;
  }

  .cont.contOpen {
    border-radius: 15px 15px 0 0 ;

  }

  .select .list {
    background-color: white;
    border-radius: 0 0 15px 15px;
    padding-bottom: 1.5rem;
  }

  .select .item {
  }

  .cont-inner input {
    padding: 1.5rem 2rem;
    background: none;
    color: black;
    font-size: 1.1rem;
    padding-left: 1.2rem;
  }
  .cont-inner label {
    padding-left: 1.2rem;
  }

  
</style>
