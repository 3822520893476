<script setup>
import { defineProps } from 'vue'

import WidgetReputation from '@/components/Query/Widget/Reputation.vue'
import WidgetCountry from '@/components/Query/Widget/Country.vue'
import WidgetProvider from '@/components/Query/Widget/Provider.vue'
  
const props = defineProps({
  query: String
});

const data = [
  ["dev.example.com", "12.34.53.21", {country_iso: "dk", country_name: "denmark" },  { name: "Amazon", id: "13009767-ba66-47fc-8929-7683e1ab4f3f"}, "AS1234", 1],
  ["store.example.com", "88.34.53.21",{country_iso: "us", country_name: "United States" },   { name: "Github", id: "76518e1a-c8da-490d-ad0f-a9260767d5ee"}, "AS1239", 3],
  ["evil.example.com", "65.34.53.21",{country_iso: "us", country_name: "United States" },   { name: "Azure", id: "e3d79c6d-b122-4748-bf9a-ff207c13ac21"}, "AS1234", 1],
  ["readme.example.com", "22.34.53.21",{country_iso: "us", country_name: "United States" },   { name: "Fastly", id: "3a5c1f5f-52ac-4657-979f-f8a5aaca5f0c"}, "AS1234", 2],
  ["prod.example.com", "231.34.53.21",{country_iso: "co", country_name: "Columbia" },   { name: "Fastly", id: "3a5c1f5f-52ac-4657-979f-f8a5aaca5f0c"}, "AS1234", 2],
  ["stage-test.example.com", "112.34.53.21",{country_iso: "co", country_name: "Columbia" },   { name: "Amazon", id: "13009767-ba66-47fc-8929-7683e1ab4f3f"}, "AS1232", 1],
  ["auth.example.com", "9.34.53.21",{country_iso: "ru", country_name: "Russia" },   { name: "Digitalocean", id: "10a7caf2-d737-4509-b105-b9e5071140a9"}, "AS1234", 1],
  ["store-stage.example.com", "12.34.53.21",{country_iso: "ru", country_name: "Russia" },   { name: "Hetzner", id: "a39395e9-0fa4-4e8d-b003-b5b096c698bb"}, "AS1234", 5],
  ["test-store.example.com", "112.34.33.21",{country_iso: "mx", country_name: "Mexico" },   { name: "Linode", id: "50f8bcb7-60f7-48f2-857f-cc8b548620c4"}, "AS1232", 2],
  ["passwords.example.com", "64.34.53.1",{country_iso: "mx", country_name: "Mexico" },   { name: "Linode", id: "50f8bcb7-60f7-48f2-857f-cc8b548620c4"}, "AS1232", 2],
]

</script>
<template>
  <div>
    <!-- SECTION -->
    <div class="box dark padded">
      <div>
        <form @submit.prevent="">
          <input type="text" :value="props.query" disabled placeholder="Enter a domain or IP"/>
          <font-awesome-icon style="color: inherit; font-size: 1rem; padding-right: 1rem;" :icon="['fas', 'magnifying-glass']" />
        </form>
      </div>  

      <!-- results -->
      <div class="mt-3">
          <!-- first row -->
            <!-- first item -->
            <div class="col-md-12 box">
              <table class="">
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>IP</th>
                      <th>Location</th>
                      <th>Provider</th>
                      <th>ASN</th>
                      <th>Reputation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="e in data" :key="e">
                      <td>{{ e[0] }}</td>
                      <td>{{ e[1] }}</td>
                      <td><WidgetCountry :data="e[2]" /></td>
                      <td><WidgetProvider :data="e[3]"/></td>
                      <td>{{ e[4] }}</td>
                      <td> <WidgetReputation :data="e[5]"/></td>
                    </tr>
                  </tbody>
              </table>
            </div>
            
      </div> <!-- end results -->

    </div> <!-- end box --> 
  </div>
</template>

<style scoped>
  
.box.dark {
 background-color: #1B2029;
 border: 2px solid #2B3541;
 border-radius: 20px; 
 padding: 1rem;
 /* grow to 2rem on search result */
}

.box.transparent {
  background-color: transparent;
  padding: 1.5rem;
}

.box.spec {
  padding: 1rem 1.5rem;
}

form {
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 10px;
  border: 2px solid #2B3541;
}

form input {
  flex: 1;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: white;
  padding: .5rem 1rem;
  font-weight: bold;
}


table {
  width: 100%;
}

th {
  text-align: left;
  color: white;
  border-bottom: 1px solid #2B3541;
  padding-top: .7rem;
  padding-bottom: .7rem;
}

td {
  text-align: left;
  color: white;
  padding-top: .7rem;
  padding-bottom: .7rem;
  padding-left: 1rem;
  border-bottom:  1px solid #2e3742;
}

.b {
  border-radius: 10px;
  border: 1px solid;
  padding: .2rem .7rem;
}

.g {
  border-radius: rgb(19, 206, 102);
  color: rgb(19, 206, 102);
}

.gr {
  border-radius: #616B79;
  color: #616B79;
}

.o {
  border-color: orange; 
  color: orange
}

.r {
  border-color: rgb(202, 80, 80); 
  color: rgb(202, 80, 80);
}

</style>
