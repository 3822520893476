<script setup>
import {defineProps, watch, onMounted, ref} from 'vue'

const props = defineProps({
    "show": Boolean,
    "properties": Object,
    "component": Object,
    "options": Object
})

const default_options = {
  'width': 50,
}

const options = ref({...default_options})

onMounted(() => {
  if (props.options != undefined && props.options != null) {
    options.value = Object.assign(options.value, props.options)
  }
}) 

watch(() => props.options, (newval) => {
  if (newval == undefined || newval == null) {
    options.value = {...default_options}
    return
  }

  options.value = Object.assign(options.value, props.options)
})

</script>

<template>
  <div v-if="props.show" @click.self="$emit('modal-close')" @keydown.esc="$emit('modal-close')" id="overlay">
    <div id="modal" class="cotainer">
      <button type="button" @click="$emit('modal-close')" class="btn-close btn-close-white" aria-label="Close"></button>
      <component v-if="props.component || props.component == {}" @modal-close="$emit('modal-close')" :is="props.component" v-bind="props.properties" id="component"/>
      <slot v-else />
    </div>
  </div>
</template>

<style scoped>
.btn-close {
  position: absolute; 
  right: 2rem;
  top: 2rem;
}

#overlay {
  background: #191f27a6;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: start;
  justify-content: center;
padding-top: 10rem;
}

#modal {
  position: relative;
  background-color: #2A323E;
  /* left: 50%; */
  width: 40%;
  z-index: 100;
  padding: 2rem;
  border-radius: 10px;
}

#component {
  height: 100%;
  overflow: scroll;
}
</style>
