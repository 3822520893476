<script setup>
import { defineProps, onMounted, ref } from 'vue'
import UserApi from '@/api/User.js'
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const emit = defineEmits(['modal-close'])

const is_running = ref(false)
const failed = ref(false)
const respone_obj = ref(null)

const props = defineProps({
  query: { type: String },
  method: { type: String },
  name: { type: String },
  body: { type: Object },
})

async function get() {
  try {
    let { data } = await axios.get(API_URL + props.query);
    respone_obj.value = data
  } catch {
    failed.value = true
  }
}

async function post() {
    let { data } = await axios.post(API_URL + props.query, props.body);
    return data;
}

onMounted(async () => {
  is_running.value = true

  if (props.method == "get" || props.method == "GET") {
    await get()
  } else if (props.method == "post" || props.method == "POST") {
    await post()
  } else {
    failed.value = true
  }
  /*
  let res = await UserApi.contact( props.sender, props.product, props.message)

  if (res.status == "failed") {
    failed.value = true
  }
  is_running.value = false
  */
  is_running.value = false
})
</script>

<template>
  <div v-if="failed">
    <div>
      <div class="text-center mb-4">
         <font-awesome-icon style="font-size: 5rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
      </div>

      <h3 class="title mb-4 text-center">Failed</h3>
      <div class="text-center d-flex column centered">
        <p style="max-width: 700px;">Sorry! Something went wrong. Ensure that the request parameters are correct.</p>
      </div>
    </div>
  </div>
  <div v-else-if="is_running">
    <div>
      <div class="text-center mb-4">
         <font-awesome-icon style="font-size: 5rem;" :icon="['fas', 'gear']" spin />
      </div>

      <h3 class="title mb-4 text-center">Sending...</h3>
      <div class="text-center d-flex column centered">
        <p style="max-width: 700px;">Your response will be ready in a second :)</p>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div class="title white mb-4">
      <span v-if="props.method == 'GET' || props.method == 'get'" class="badge rounded-pill text-bg-success">GET</span> 
      <span v-else-if="props.method == 'POST' || props.method == 'post'" class="badge rounded-pill text-bg-primary">POST</span> 
      <span v-else class="badge rounded-pill text-bg-secondary">{{ props.method }}</span>
      {{ props.name }} 
    </div>

    <div class="row">
      
      <div class="col-md-6">
        <div class="menu-container">
          <h5 class="mb-3">RESPONSE</h5>
          <div class="box padded">
            <vue-json-pretty :data="respone_obj" style="overflow: auto; max-height: 600px;"/>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="menu-container">
          <h5 class="mb-3">REQUEST</h5>
          <div class="box padded">
            {{ API_URL + props.query }}
          </div>
        </div>

        <div v-if="props.body != null && props.body != undefined && props.body != ''"  class="menu-container">
          <h5 class="mb-3">REQUEST BODY
          </h5>
          <div class="box padded">
            <vue-json-pretty :data="props.body" style="overflow: auto; max-height: 600px;"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 2rem; 
}

.option-title {
  display: flex;
  background-color: #151a22;
  color: white;
  padding: 1rem;
}

.option {
  background-color: #272F3B;
  padding: 1rem;
  display: flex;
  border-left: 1px solid  #151a22;
  border-right: 1px solid  #151a22;
  border-bottom: 1px solid  #151a22;
  color: white;
}

.option:hover {
  background-color: #363f4d;
  cursor: pointer;
}

.form-control:disabled {
  background-color: #1F2631;
  color: #ffffffba
}

.button {
  background-color: #1E252F;
  border-radius: 10px;
  border:none;
  font-weight: bold;
  color: white;
  padding: .5rem 2rem;
}

.button.dark {
  background-color: #131820
}

.button:hover {
  background-color: #2B3440;
}

.v-enter-active {
  transition: opacity 1s ease;
}
.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
