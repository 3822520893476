<script setup>
import { defineProps } from 'vue'
import { useQueryStore } from '@/stores/query'

import QueryExample from '@/components/Docs/QueryExample.vue'

let queryStore = useQueryStore()

const props = defineProps({
  title: { 
    type: String,
    default: '',
  },
  query: { 
    type: String,
    default: '',
  },

  description: { 
    type: String,
    default: '',
  },

  type: {
    type: String,
    default: "any"
  },

  bulk: {
    type: String,
    default: "any"
  },

  icon: { 
    type: String,
    default: 'chart-simple',
  },

  color: { 
    type: String,
    default: 'white',
  },

  yields: {
    type: Array,
    default: [],
  }
})

</script>

<template>
  <div class="b d-flex column">
    <div class="box mb-3 med-padded d-flex vertically-centered">
      <font-awesome-icon :style="{'color': props.color}" style="font-size: 1.2rem;" class="me-4" :icon="['fas', props.icon]" />
      <div class="title mb-0">{{ props.title }}</div>
      <div class="ms-auto d-flex">
        <div v-if="props.type == 'any'"><div class="t-any">any</div></div>  
        <div v-else-if="props.type == 'ip'"><div class="t-ip">ip address</div></div>  
        <div v-else-if="props.type == 'domain'"><div class="t-domain">domain</div></div>  

        <div v-if="props.bulk" class="ms-2"><div class="t-bulk">+bulk</div></div>  


      </div>
    </div>

    <div class="b-body d-flex column">

      <div class="mb-3">
        <strong class="white">{{ props.description }}</strong>
      </div>
      
      <div class="">
        <query-example class="mb-3" :query="props.query" />

        <div v-if="props.yields.length > 0 ">
          <div style="color: #B6CBE5">Yields</div>
          <div>
            <span v-for="y, idx in props.yields" class="s">{{y}}<strong style="color:#B6CBE5" v-if="idx != props.yields.length -1">, </strong></span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
.b {
  background-color: #1A212B;
  border-radius: 0 0 10px 10px;
}

.s {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}

.b-body {
  flex: 1;
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
}

.title {
  font-size: 1.1rem;
  color: white;
  font-weight: bold;
}

.title-small {
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.play-group {
  margin-left: -.7rem;
  margin-right: -0.7rem;
}

.play-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #333d4a;
}

.title.usage {
  font-size: 1.1rem;
  color: #A3A9B0;
  font-weight: normal;
}

.t-ip {
  color: #8BC186;
  border: 1px solid #8BC186;
  padding: 0 0.8rem;
  border-radius: 10px;
  font-size: .9rem;
}

.t-any {
  color: #BA86C1;
  border: 1px solid #BA86C1;
  padding: 0 0.8rem;
  border-radius: 10px;
  font-size: .9rem;
}

.t-domain {
  color: #C1A286;
  border: 1px solid #C1A286;
  padding: 0 0.8rem;
  border-radius: 10px;
  font-size: .9rem;
}

.t-bulk {
  color: #616B79;
  border: 1px solid #616B79;
  padding: 0 0.8rem;
  border-radius: 10px;
  font-size: .9rem;
}

</style>
