const domain_regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z]/g;
const ip_regex = /\b(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\b/g;

module.exports = {
  
  // input is a string of the file content
  analyze( input ) {

    results = { domains: [...input.matchAll(domain_regex)], ips: [...input.matchAll(ip_regex)] }

    return results
  },

}
