import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Articles {
  async list() {
    let { data } = await axios.get(API_URL + '/articles/search/none/0/none');
    return data;
  }
  
  async indicators(article_id) {
    let { data } = await axios.get(API_URL + '/articles/'+article_id+'/indicators');
    return data;
  }

  async search(query, tags, offset) {
    if (query == "" || query == null) query = "none"

    if (tags.length > 0) {
      let { data } = await axios.get(API_URL + '/articles/search/'+query+'/'+offset+'/'+tags.join(","));
      return data;
    } else {
      let { data } = await axios.get(API_URL + '/articles/search/'+query+'/'+offset+'/none');
      return data
    }
  }

}

export default new Articles();
