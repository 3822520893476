import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Domain {
  async emails(query) {
    let { data } = await axios.get(API_URL + '/query/domain/'+query+"/emails", {validateStatus: false});
    return data;
  }

  async resolves(query) {
    let { data } = await axios.get(API_URL + '/query/domain/'+query+"/history", {validateStatus: false});
    return data;
  }

  async subdomains(query) {
    let { data } = await axios.get(API_URL + '/query/domain/'+query+"/subdomains", {validateStatus: false});
    return data;
  }

  async reverse_dns(ip) {
    let { data } = await axios.get(API_URL + '/query/ip/'+ip+"/reverse_dns", {validateStatus: false});
    return data;
  }

}

export default new Domain();
