import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Streams {
  async list() {
    let { data } = await axios.get(API_URL + '/streams/list');
    return data;
  }

  async list_items(stream_id) {
    let { data } = await axios.get(API_URL + '/streams/'+stream_id+'/items');
    return data;
  }

  async contains(item) {
    let { data } = await axios.get(API_URL + '/streams/check/'+item);
    return data;
  }
  
}

export default new Streams();
