<script setup>
import {defineProps, watch, defineEmits, onMounted, ref, nextTick} from 'vue'

const emit = defineEmits([ "value" ]);

const props = defineProps({
  name: String,
  type: String,
  required: Boolean,
  description: String,
  value: String
})

var v = ref(props.value)

</script>

<template>
  <div>
    <div class="form-floating">
        <input v-model="v" @keyup="$emit('value', v)" class="form-control" id="floatingInput" placeholder="">
        <label for="floatingInput" class="d-flex vertically-centered"><span class="me-2">{{props.name}}</span> 
          <span v-if="props.type == 'string'" class="badge text-bg-primary">string</span>
          <span v-if="props.type == 'integer'" class="badge text-bg-warning">integer</span>
          <span v-if="props.required" class="ms-2 badge text-bg-danger">required</span>
        </label>
    </div>
    <p class="ps-1 mt-1">{{ props.description }}</p>
  </div>
</template>

<style scoped>

.form-control {
  padding: 1rem;
  border-radius: 10px;
  border:1px solid #313946;
}

label {
  padding:1rem;
  font-weight: normal;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

</style>
