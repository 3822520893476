<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'
const userStore = useUserStore()
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

import { onMounted,ref } from 'vue'
import Slideover from '../../../components/SlideOver/slideover.vue'
import CreateForm from '../../../components/SlideOver/forms/CreateApiKey.vue'
import User from '../../../api/User';
import StandardTable from '../../../components/Table/Standard.vue'
import moment from 'moment'

const keys = ref([])

const show_slideover = ref(false)
const slideover_component = ref(null)
const slideover_props = ref(null)

onMounted(async () => {
  keys.value = (await User.list_api_keys()).data;
})

async function close() {
  show_slideover.value = false
  keys.value = (await User.list_api_keys()).data;
} 

function show_create_key() {
  mainStore.show_slideover(CreateForm, { },)
}

async function click_handler(id) {
  await User.delete_api_key(id)
  keys.value = (await User.list_api_keys()).data;
}

mainStore.event_on("created_apikey", async () => {
  keys.value = (await User.list_api_keys()).data;
})
</script>

<template>
  <div style="min-height: 100%;" class="d-flex column">

    <div class="container-fluid" style="flex:1; height: calc(100% - 60px)">
        <!-- Content -->
        <div class="col-md-10 pb-4" style="height: 100%;">

          <div class="row" style="height: 100%;">
            <div class="col-md-12">
              <div class="container mt-5">
                <div class="row">
                  <div class="col-md-12">
                    <h3>{{ userStore.name }}</h3>
                    <p>{{ userStore.email }}</p>
                    <hr>
                  </div>
                </div>

                <!-- API KEYS --> 
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <font-awesome-icon class="me-2" style="color: rgb(231 242 92);" :icon="['fas', 'key']" />
                      <strong class="white">API keys</strong></div>
                    <div class="box small-padded mb-2 d-flex" v-for="key in keys" :key="key">
                        <div class="col-md-6" style="flex:1">
                          <div><strong class="white">{{key.Name}}</strong></div>
                          <div>{{key.Hash}}</div>
                        </div>
                        <div class="d-flex vertically-centered">
                          <div class="trash-btn" @click="click_handler(key.Id)">
                          <font-awesome-icon style="font-size: 16px;" :icon="['fas', 'trash']"/>
                          </div>
                        </div>
                    </div>

                    <div class="box dark med-padded clickable mt-2" style="display: inline-block"  @click="show_create_key()">
                      <font-awesome-icon style="font-size: 14px;" class="me-2" :icon="['fas', 'plus']"/><strong>API Key</strong>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- Router view show the correct API page -->
            <!-- <router-view class="wrap"></router-view> -->
        </div>

        <!-- Navigation -->
        <div class="col-md-2 sidebar pt-3">
        <!--
            <strong class="mb-2 d-flex"><small>Core</small></strong>
            <div class="list-group list-group-dark mb-4" data-bs-theme="dark">
              <router-link class="list-group-item list-group-item-action" to="/docs/api">Introduction</router-link>
            </div>
            <strong class="mb-2 d-flex"><small>Asset information</small></strong>
            <div class="list-group list-group-dark mb-4" data-bs-theme="dark">
              <router-link class="list-group-item list-group-item-action" to="/docs/api/asset/domain">Domain</router-link>
              <router-link class="list-group-item list-group-item-action" to="/docs/api/asset/ip">IP</router-link>
              <router-link class="list-group-item list-group-item-action" to="/docs/api/asset/url">URL</router-link>
              <router-link class="list-group-item list-group-item-action" to="/docs/api/asset/artifact">Artifact</router-link>
              <router-link class="list-group-item list-group-item-action" to="/docs/api/asset/hash">Hash</router-link>
            </div>

            <strong class="mb-2 d-flex"><small>Data streams</small></strong>
            <div class="list-group list-group-dark" data-bs-theme="dark">
              <router-link class="list-group-item list-group-item-action" to="/docs/api/news">Threat News</router-link>
              <router-link class="list-group-item list-group-item-action" to="/docs/api/feeds">Feeds</router-link>
            </div>
          -->
        </div>

      </div>

    </div>

  </div>
</template>

<style scoped>
.sidebar {
  border-left: 1px solid #1e252f;
}

.list-group-item {
  background-color: #191f27;
  border-color: #1e252f;
  color: #6d7785;
}

.list-group-item:hover {
  background-color: #1c222b
}

.router-link-exact-active {
  font-weight: 500 !important;
  color: white !important;
}

.trash-btn {
    padding: 0.5rem 0.8rem;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: .2s all ease;
}

.trash-btn:hover {
  color: rgb(242 92 92);
  border-color: rgb(242 92 92);
  cursor: pointer;
}
</style>
