<script setup>
import { defineProps } from 'vue'
import { useQueryStore } from '@/stores/query'
import { useRouter } from 'vue-router'
const router = useRouter()
let queryStore = useQueryStore()

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  query: String,
})


async function query(input) {
  
  await queryStore.query(input)

  router.push({
    name: "UserQuery",
  })
}

</script>

<template>
  <div :class="{'box med-padded': props.title != '' }">
    <div v-if="props.title != ''" class="title-small mb-3">
      {{ props.title }}
    </div>
    <div class="box dark p-2 clickable play-group" @click="query(props.query)">
      <code>{{ props.query }}</code>
      <div class="play-btn">
        <font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'play']" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 1.1rem;
  color: white;
  font-weight: bold;
}

.title-small {
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.play-group {
  margin-left: -.7rem;
  margin-right: -0.7rem;
}

.play-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #333d4a;
}

.title.usage {
  font-size: 1.1rem;
  color: #A3A9B0;
  font-weight: normal;
}

code {
  padding: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
</style>
