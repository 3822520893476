<script setup>
import search from '@/components/Search.vue'
import { defineProps, onMounted, ref, watch, computed } from 'vue'
import { useUserStore } from '@/stores/user'
import Search from '../api/Search.js'
import { useRoute } from 'vue-router'
import StandardTable from '../components/Table/Standard.vue'

import Slideover from '../components/SlideOver/slideover.vue'
import StreamPresence from '../components/StreamPresence.vue'
import ArticleList from '../components/ArticleList.vue'
import Vendors from '../components/Vendors.vue'

const route = useRoute()
const loading = ref(false)
const empty = ref(false)

const query = ref("")

const user = useUserStore()

const props = defineProps(['domain'])

const result = ref([])

const show_slideover = ref(false)
const slideover_component = ref(null)
const slideover_props = ref(null)

async function lookup_domain(domain) {
  loading.value = true
  query.value = route.params.domain
  // This might take some time while we check the domain is valid.
  result.value = await Search.domain(domain)
  
  loading.value = false
} 

onMounted(async () => {
  await lookup_domain(route.params.domain)
})

watch(() => route.params.domain, async (newval) => {
  if (newval == undefined) return
  await lookup_domain(route.params.domain)
})

let col = [
  { data: "Domain" },
  { data: "Ip", render: (data, type, row, meta) => {
    let s = ""
    if (row.CountryIso != "") {
      s += '<img height="12" class="me-2" style="border-radius: 5px;" src="/flags/4x3/' + row.CountryIso.toLowerCase() + '.svg" alt="">'
    }
    s += data
    return s 
  }},
  { data: "OsGuess" },
  { data: "Ports", render: (data) => {
    if (data == null) return ""
    return data.join(", ")
  } },
  { data: "Provider" }
]

const stream_hits = computed( () => {
  if (result.value.Streams == null) return [0,0]
  let items = result.value.Streams.length;
  let flags = 0;

  for (const s of result.value.Streams) {
      if (s.Featured) flags += 1
  }
  return [flags, items] 
})

const vendor_flags = computed( () => {
  if (result.value.Vendors == null) return [0,0]
  let items = result.value.Vendors.length;
  let flags = 0;

  for (const s of result.value.Vendors) {
    if (s.Flag != false) {
      flags += 1
    } 
  }

  return [flags, items]
})

function show_streampresence() {
  slideover_props.value = { "item": result.value.Domain, "data": result.value.Streams }
  slideover_component.value = StreamPresence
  show_slideover.value = true
}

function show_articles() {
  slideover_props.value = { "item": result.value.Domain, "data": result.value.Articles }
  slideover_component.value = ArticleList
  show_slideover.value = true
}

function show_vendors() {
  slideover_props.value = { "item": result.value.Domain, "data": result.value.Vendors }
  slideover_component.value = Vendors
  show_slideover.value = true
}
</script>  

<template>
<div>
    <slideover :show="show_slideover" :component="slideover_component" :properties="slideover_props" @slideover-close="show_slideover = false">
    </slideover>
    <div class="container-fluid mb-4" style="background-color: #171C24">

      <div class="container pt-4 pb-4">
          <div class="row">
              <div class="col-md-12">
                  <search id="search" :search_value="query">
                    <template v-slot:trigger>
                        <button id="btn-trigger" class="btn">
                            Lookup
                        </button>
                    </template>

                    <template v-slot:invalidtrigger>
                         <button id="btn-trigger" disabled class="btn">
                            Lookup
                        </button>
                    </template>
                          
                  </search>
              </div>
          </div>
      </div>
    </div>
    

    <div v-if="!loading">
    <!-- if domain/url search  --> 
    <div class="container mb-4">
      <div class="row mb-3" v-if="result.Info">
        <div class="col-md-12">
          <h1 class="mb-0">{{ result.Info.Domain }}</h1>
          <div class="d-flex vertically-centered">
            <img v-if="result.Info.CountryIso" height="12" class="me-2" style="border-radius: 5px;" :src="require('@/assets/flags/4x3/' + result.Info.CountryIso.toLowerCase() + '.svg')" alt="">
            <span class="me-3">{{result.Info.Ip}}</span>
            <span class="me-3">{{result.Info.ASN.toUpperCase()}}</span>
            <span>{{result.Info.ASOrg}}</span>
          </div>
        </div>
      </div>

      <div class="row mb-3">

        <div class="col-md-3">
          <div class="box small-padded clickable" @click="show_vendors()">
            <div class="d-flex fingerprint-box vertically-centered">
              <div class="d-flex column">
                <span class="head">Security Vendor Flags</span>
                <span>{{ vendor_flags[0] }} / {{ vendor_flags[1] }}</span>
              </div>
              <div class="ms-auto">
                <font-awesome-icon v-if="vendor_flags[0] == 0" style="font-size: 1.2rem; color: #82ca50;" :icon="['fas', 'circle-check']"/>
                <font-awesome-icon v-else class="ms-auto" style="font-size: 1.2rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="box small-padded clickable" @click="show_streampresence()">
            <div class="d-flex fingerprint-box vertically-centered">
              <div class="d-flex column">
                <span class="head">Indicator feeds</span>
                <span>{{ stream_hits[0] }} / {{ stream_hits[1] }}</span>
              </div>
              <div class="ms-auto">
                <font-awesome-icon v-if="stream_hits[0] == 0" style="font-size: 1.2rem; color: #82ca50;" :icon="['fas', 'circle-check']"/>
                <font-awesome-icon v-else class="ms-auto" style="font-size: 1.2rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="box small-padded clickable" @click="show_articles()">
            <div class="d-flex fingerprint-box vertically-centered">
              <div class="d-flex column">
                <span class="head">Threat Intel Mentions</span>
                <div v-if="result.Articles != null">
                  <span v-if="Object.keys(result.Articles).length <= 30">{{ Object.keys(result.Articles).length }}</span>
                  <span v-else>30+</span>
                </div>
                <div v-else>
                  <span>0</span>
                </div>
              </div>
              <div class="ms-auto">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="box small-padded clickable">
            <div class="d-flex fingerprint-box vertically-centered">
              <div class="d-flex column">
                <span class="head">First seen</span>
                <span>2 months ago</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row"> 
        <div class="col-md-12" v-if="result.Subdomains">
          <StandardTable
            class="box padded"
            :columns="col"
            :data="result.Subdomains"
            title="Subdomains">
            <thead>
              <tr>
                <th>Domain</th>
                <th>IP</th>
                <th>Os Guess</th>
                <th>Open Ports</th>
                <th>Provider</th>
              </tr>
            </thead>
          </StandardTable>
        </div>
      </div>
    </div>

      <div  style="color: white !important;" class="d-flex column vertically-centered">
        <div class="mb-1 d-flex vertically-centered">
          Whoops! Seems you have hit the result limit. To see more,&nbsp;<router-link to="/signup">register here</router-link>&nbsp;🫡
        </div>
      </div>

    </div>
    <div v-else-if="loading">
        <div class="d-flex column centered pt-5" style="height: 100%;">
          <font-awesome-icon style="font-size: 7rem;" class="mb-4" :icon="['fas', 'compact-disc']" spin />
          <div class="d-flex centered column">
            <h4 class="text-center">Tasking the bits...</h4>
            <p>It should only take a sec ;)</p>
          </div>
        </div>
    </div>
    <div v-else>
        Failed
    </div>

</div>
</template>

<style scoped>
  #btn-trigger {
      padding: .6rem 1.4rem; 
      background-color:#7357D2;
      font-weight: bold;
      outline:none;
      box-shadow: none;
      border:none;
      color: white;
      z-index: 5;
      border-radius: 10px;
      transition: all .3s ease;
  }

  #btn-trigger:hover {
      background-color: #7F62E3;
  }

#search >>> .cont {
    /* background-color: #2F3846; */
    background-color: #131820;
}

#search >>> input {
    color: white;
}

.number {
    color: white;
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.progressbar-outer {
    height: .4rem;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
}

.progressbar-inner {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: #191f27;
    transition: all ease .5s;
}


#scrollspy {
    border-left: 1px solid #414d5d;
    padding-top: 0;
}

#scrollspy a {
    text-decoration: none;
    color: #727f8f;
}

#scrollspy a:hover {
    color: #bbc4cf;
}

#scrollspy a.active {
    color: white;
    /* border-left: 3px solid white; */
}

.fixed {
    position: fixed;
    top: 90px;
}

.fingerprint-box .head {
  font-weight: bold;
  color: white;
}

.fingerprint-box {
  border-radius: 10px;
  padding: .3 rem;
  padding: 0 .5rem;
  margin: 0 -.5rem;
  }

.fingerprint-box:hover {
  background-color: #262e3a;
  cursor: pointer;
}

.fingerprint-box .sub {
  font-weight: light;
  font-size: .8rem;
}

</style>
