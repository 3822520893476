<script setup>
import {defineProps, ref, onMounted, computed} from 'vue'

const props = defineProps(['item', 'data'])

const loading = ref(false)
const articles = ref([]) 
const feed_hits = computed(() => {})

onMounted(async () => {
  loading.value = false;
  
  if (props.data != undefined && props.data != null) {
    articles.value = props.data
  } else {
    // TODO: 
    // load the indicators from the API 
  }

  loading.value = true;
})
</script>

<template>
  <div class="streams">
    <h5>{{ props.item }}</h5>
    <hr>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-2" v-for="article in articles">
          <div class="d-flex box small-padded clickable vertically-centered">
            <a :href="article.Url" class="text-white d-flex">
              <span>{{article.Title}}</span>
              <font-awesome-icon class="ms-auto" style="color: #536173; font-size: 1rem;" :icon="['fas', 'link']" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.streams {
  padding: 2rem;
}
</style>
