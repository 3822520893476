<script setup>
  
</script>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<style scoped>
  
</style>

