<script setup>
import {defineProps, computed} from 'vue'

const props = defineProps(["data"])

function try_file(id) {
  try {
    return require('@/assets/icons/providers/' + id + '.svg')
  } catch {
    try {
      return require('@/assets/icons/providers/' + id + '.png')
    } catch {
      return ""
    }
  }
}
</script>

<template>
  <div class="d-flex vertically-centered" v-if="props.data != null">
    <img v-if="props.data.name != ''" height="16" class="me-2" :src="try_file(props.data.id)" alt="">
    <span>{{ props.data.name }}</span>
  </div>
</template>

<style scoped>

</style>
