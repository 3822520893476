import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

class Auth {

  async login(email, password) {
    let { data } = await axios.post(API_URL + '/auth/login', { email: email, password: password });
    return data
  }

  async logout() {
    return await axios.get(API_URL + '/auth/logout');
  }

  async register(email, password, name) {
    try {
      let { data} =  await axios.post(API_URL + '/auth/register', { email: email, password: password, name: name });
      return data
    } catch( errors ) {
      return errors.response
    }
  }

  async loggedIn() {
    let {data} = await axios.get(API_URL + '/auth/state')
    return data.loggedIn; 
  }

  async newsletter_signup(email) {
     return await axios.post(API_URL + '/waitlist', { email: email });
  }
  
}

export default new Auth();
