<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

mainStore.nav_resources = 'About'
</script>

<template>
<div class="container pt-5">
  <div class="row">
    <div class="col-md-12">
        <h1 >About</h1>
        <p>Based in the heart of Copenhagen, Denmark, Webscout is an internet intelligence company specialized in tagging and enriching IP addresses and domain names with vital context, behavioral insights, and important metadata. Our mission is to supercharge threat detection and prevention by providing organizations with comprehensive and actionable internet intelligence. </p>
    </div>

    <div class="col-md-12">
        <h3>Intelligence</h3>
        <p>Current IP address and domain enrichments include:</p>
        <ul>
          <li>Geolocation Data: Precise location information for IP addresses worldwide.</li>
          <li>Advanced Fingerprints: Including JA3, JA3S, HASSH, and more for large-scale profiling of internet systems.</li>
          <li>IP to Organization Attribution: Linking IP addresses to their associated organizations.</li>
          <li>VPN, Tor, and Proxy Detection: Identifying the use of anonymization services.</li>
          <li>Threat Observations: Real-time data on malicious activities linked to IPs and domains.</li>
          <li>Behavioral Insights from Netflow: Understanding traffic patterns and behaviors.
...and more.</li>
          <li>...and more.</li>
        </ul>
    </div>
    <div class="col-md-12">
        <h3>Use cases</h3>
        <p>Our solutions empower organizations in:</p>
        <ul>
          <li>Proactive Defense: Anticipate and mitigate threats before they impact your operations.</li>
          <li>Cyber Threat Intelligence: Equip your security teams with data to identify and respond to emerging threats effectively.</li>
          <li>DFIR Analysis: Enhance your Digital Forensics and Incident Response efforts with enriched data and insights for thorough investigations.</li>
          <li>Training Data for Detection Systems: Provide high-quality data to train and improve the accuracy of your threat detection systems.</li>
        </ul>
    </div>

    <div class="col-md-12">
        <h3>Contact</h3>
        <p>Contact us at info@webscout.io to learn how we can enhance your threat detection and prevention capabilities.</p>
    </div>
  </div>

</div>

</template>

<style scoped>

</style>

