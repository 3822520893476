import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Query {

  async custom(query) {
    try {
      const  data  =  await axios.post(API_URL + '/query/custom', { query: query });
      return data.data; 
    } catch(error) {
      if (error.response.status == 429) {
        return { status: "ratelimited", data: {} }
      } else if (error.response.status == 401) {
        return { status: "unauthorized", data: {} }
      } else {
        return { status: "failed", data: {} }
      }
    }

  }

  async subdomains(domain) {
    try {
      const  data  =  await axios.get(API_URL + '/query/domain/'+domain+"/subdomains");
      return data.data; 
    } catch(error) {
      if (error.response.status == 429) {
        return { status: "ratelimited", data: {} }
      } else if (error.response.status == 401) {
        return { status: "unauthorized", data: {} }
      } else {
        return { status: "failed", data: {} }
      }
    }

  }

  async domain(domain) {
    try {
      const  data  =  await axios.get(API_URL + '/query/domain/'+domain);
      return data; 
    } catch(error) {
      if (error.response.status == 429) {
        return { status: "ratelimited", data: {} }
      } else if (error.response.status == 401) {
        return { status: "unauthorized", data: {} }
      } else {
        return { status: "failed", data: {} }
      }
    }
  }

  async ip(ip) {
    try {
      const  data  =  await axios.get(API_URL + '/query/ip/'+ip);
      return data; 
    } catch(error) {
      if (error.response.status == 429) {
        return { status: "ratelimited", data: {} }
      } else if (error.response.status == 401) {
        return { status: "unauthorized", data: {} }
      } else {
        return { status: "failed", data: {} }
      }
    }
  }

  async jarm(jarm) {
    const { data } =  await axios.get(API_URL + '/query/jarm/'+jarm);
    return data; 
  }

  async url(url) {
    try {
    const { data } =  await axios.get(API_URL + '/query/url?url='+encodeURIComponent(url));
    return data;
    } catch(error) {
    return { Status: "invalid", Data: {} }
    }
  }

  async url_status(id) {
    const { data } =  await axios.get(API_URL + '/query/url/status?url='+id);
    return data;
  }

  async url_result(id) {
    try {
      const { data } =  await axios.get(API_URL + '/query/url/result?url='+id);
      return data;
    } catch(error) {
      if (error.response.status == 429) {
        return { Status: "ratelimited", Data: {} }
      } else if (error.response.status == 401) {
        return { Status: "unauthorized", Data: {} }
      } else {
        return { Status: "failed", Data: {} }
      }
    }
  }

  async recent() {
    const { data } =  await axios.get(API_URL + '/query/url/recent');
    return data;
  }
}

export default new Query();
