import { defineStore } from 'pinia'

import Query from "@/api/Query.js"

/*
  Install as Store as Pinia.

  Create an api js file in a separate folder with useAxios inside and define a function that fetches to your backend server.

  Define an action inside your Pinia store that calls the api method and sets the data inside the store.

  Create a computed inside your Component that returns the store value.

  Call the Action inside created with async await inside your Component.
*/

export const useQueryStore = defineStore('query', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      query_string: "",
      
      error: "",

      result: null,

      running: false,

      display_json: false,
    }
  },

  getters: {
    failed: (state) => {
      return state.error != ""
    },

    empty: (state) => {
      return state.result == null
    },

    valid_query: (state) => {
      return state.query_string != ""
    }
  },

  actions: {
    reset() {
      this.result = null; 
      this.error = "";
    },

    async query(query) {
      this.query_string = query
      if (this.query_string == '') {
        this.reset()
        return
      }

      this.running = true

      // this.result = run query (await)
      let res = await Query.custom(query)
      if (res.status == "success") {
        this.result = res.data
        // reset the error if there was one previously.
        this.error = "" 
      } else {
        this.error = res.status
      }
      
      this.running = false
      return this.result
    }
  },

})
