<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_ip = ref("1.1.1.1")

let get_query = computed(() => {
  return "/query/ip/" + request_ip.value + "/reverse_dns" 
}) 

</script>

<template>
  <div>
    <doc-section title="Reverse DNS">
      <template v-slot:left>
        <div class="mb-5">
Using the reverse DNS endpoint users are able to get a list of domains which currently resolve to a specific IP address. The endpoint returns a maximum of 1000 (200 for free users) domains per request, but can be paginated using the optional “offset” query parameter. 
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="IP address" :required="true" description="The ip to find domains resolving to" type="string" :value="request_ip" @value="(v) => request_ip = v" />
          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array" description="The requested information">

            <BasicAttribute name="domain" type="string" description="The requested domain" />
            <hr>

            <BasicAttribute name="ips" type="string" description="The IPs which the domain resolves to, or have, if it currently doesn't resolve to any ip" />
            <hr>

            <BasicAttribute name="location" type="object array" description="The location of the ip addresses. These are orded to match those in the ips field.">
              <BasicAttribute name="country_name" type="string" description="The full name of the country" />
              <hr>
              <BasicAttribute name="country_iso" type="string" description="A two letter country code" />
              <hr>
              <BasicAttribute name="longitude" type="float" description="It's longitude" />
              <hr>
              <BasicAttribute name="latitude" type="float" description="It's latitude" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="num_emails" type="integer" description="The number of email addresses we have opserved on the domain" />
            <hr>

            <BasicAttribute name="num_subdomains" type="integer" description="The number of subdomains we have found on the domain" />
            <hr>

            <BasicAttribute name="registrant_org" type="string" description="The organization that registered the domain" />
            <hr>

            <BasicAttribute name="registrant_email" type="string" description="The email used to register the domain" />
            <hr>

            <BasicAttribute name="registration_date" type="string" description="The date the domain was registered" />
            <hr>

            <BasicAttribute name="expiration_date" type="string" description="The date the domain registration expires" />
            <hr>

            <BasicAttribute name="as" type="object array" description="AS information assosiated with the ips, these are ordered to match the ips field">
              <BasicAttribute name="number" type="string" description="The AS number" />
              <BasicAttribute name="org" type="string" description="The organizational name" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity we have observed related to the domain or it's infrastructure" />
            <hr>

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/ip/:ip/reverse_dns" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [{
"domain": "webmail.alemannolucadesign.it",
"ips": [
"1.1.1.1"
],
"location": [
{
"country_iso": ""
}
],
"num_emails": 0,
"num_subdomains": 0,
"registrant_org": null,
"registrant_email": null,
"registration_date": null,
"expiration_date": null,
"as": [
{
"number": "as13335",
"org": "CLOUDFLARENET"
}
],
"behavior": null
}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
