<script setup>
import { defineProps } from 'vue'
import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()
import { useRouter} from 'vue-router'

const router = useRouter()

const emit = defineEmits(['modal-close'])

const props = defineProps({
  fileName: { type: String },
  domains: { type: Array, default: [] },
  ips: { type: Array, default: [] }
})

async function ip_general_information() {
  queryStore.query("ip = " + props.ips.join())
  emit('modal-close')
  
  router.push({
    name: 'UserQuery'
  })
}

async function domain_general_information() {
  queryStore.query("domain = " + props.domains.join())
  emit('modal-close')
  
  router.push({
    name: 'UserQuery'
  })
}

</script>

<template>
  <div>
    <div>
      Finished analyzing: <span class="white">{{ props.fileName }}</span>
    </div>
    <div class="white title">
      <span v-if="domains.length > 0 || ips.length > 0">Findings</span>
      <span v-else>Sorry! <br> We weren't able to extract any artifacts from the file</span>
    </div>

    <div class="menu-container">
   
        <div class="menu" v-if="props.domains.length > 0">
          
          <div class="option-title">
            <div>Domains</div>
            <div class="ms-auto">{{ props.domains.length }}</div>
          </div>
          <div class="option" @click="domain_general_information()">
            <div>Domain information</div>
          </div>
        </div>

        <div class="menu" v-if="props.ips.length > 0">
          
          <div class="option-title">
            <div>IP addresses</div>
            <div class="ms-auto">{{ props.ips.length }}</div>
          </div>

          <div class="option" @click="ip_general_information()">
            <div>IP Information</div>
          </div>

        </div>
    </div>
    
  </div>

</template>

<style scoped>
.title {
  font-size: 2rem; 
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.menu {
  width: calc(50% - 10px/2)
}

.menu > div:first-child:not(:last-child) {
  border-radius: 10px 10px 0 0;
}

.menu > div:last-child:not(:first-child) {
  border-radius: 0 0 10px 10px;
}

.option-title {
  display: flex;
  background-color: #151a22;
  color: white;
  padding: 1rem;
}

.option {
  background-color: #272F3B;
  padding: 1rem;
  display: flex;
  border-left: 1px solid  #151a22;
  border-right: 1px solid  #151a22;
  border-bottom: 1px solid  #151a22;
  color: white;
}

.option:hover {
  background-color: #363f4d;
  cursor: pointer;
}

</style>
