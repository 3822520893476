import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Search {
  async search(query) {
    let { data } = await axios.get(API_URL + '/lookup/'+query, {validateStatus: false});
    return data;
  }

  async domain(query) {
    let { data } = await axios.get(API_URL + '/lookup/domain/'+query);
    return data;
  }

  async status(query) {
    const { data } =  await axios.get(API_URL + '/lookup/status/'+query);
    return data; 
  }

  async query_status(query_id) {
    const { data } =  await axios.get(API_URL + '/search/status/'+query_id);
    return data; 
  }

  async query_result(query_id) {
    const { data } =  await axios.get(API_URL + '/search/result/'+query_id);
    return data; 
  }

  async website(query_id) {
    const { data } =  await axios.get(API_URL + '/search/result/'+query_id + '/website');
    return data; 
  }

  async community_scans() {
    const { data } =  await axios.get(API_URL + '/search/community_scan');
    return data; 
  }

}

export default new Search();
