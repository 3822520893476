<script setup>
import {defineProps, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';

//const props = defineProps(['title', 'columns', 'data', 'options', 'search', 'show-searchbar', 'show-title'])
const props = defineProps({
  title: String,
  columns: Array,
  data: Object,
  options: Object,
  search: String,
  showSearchbar: { type: Boolean, default: true},
  showTitle: { type: Boolean, default: true}
})

const emit = defineEmits(['rowclick'])

DataTable.use(DataTablesCore)

let rdt; 
const rtable = ref();
const rdt_search_field = ref(null)

let options; 
if (props.options != undefined && props.options != null) {
  options = props.options
} else {
  options = {
    dom: "t",
    scrollCollapse: true,
    paging: false,
    language: { search: '', searchPlaceholder: "Search..." },
    scrollY: '500px'
  }
}

onMounted(() => {
  rdt = rtable.value.dt;
  if (props.options != undefined && props.options != null) {
    options.value = props.options
  }

  rdt.on('click', 'tbody tr td', function() {
    emit("rowclick", { 
      obj: rdt.row(this).data(),
      cell_name: rdt.column(this).header().textContent,
      //cell_data: rdt.cell(this).data() 
    })
  });
})

watch(() => options, () => {
  rdt.columns.adjust().draw() 
})

watch(() => props.data, () => {
  nextTick(() => {
    rdt.columns.adjust().draw()
  })
})

watch(() => props.search, (val) => {
  rdt.search(val).draw() 
})

function rdt_search() {
  rdt.search(rdt_search_field.value).draw() 
}
</script>

<template>
  <div class="">
    <div class="d-flex" :class="{'mb-3': props.data.length > 0 || !showSearchbar && !showTitle, 'vertically-centered': props.data.length == 0}">
      <h6 class="mb-0" v-if="showTitle">{{props.title}}</h6>
      <input v-show="props.data.length > 0 && showSearchbar" placeholder="Search..." class="dt-input ms-auto" v-model="rdt_search_field" @input="rdt_search()" />
      <span v-show="props.data.length == 0" class="ms-auto">None</span>
    </div>
    <div v-show="props.data.length > 0">
      <DataTable
        ref="rtable"
        :columns="props.columns"
        :data="props.data"
        :options="options"
        class="table table-hover table-bordered text-white">
        <slot></slot>
      </DataTable>
    </div>
  </div>
</template>

<style>
.dt-input {
  background-color: #131820;
  border: none;
  padding: .5rem 1rem;
  color: white;
  border-radius: 10px;
  outline: none;
}

/*
.datatable tr:hover {
  background-color: #262e3a;
}
*/

.datatable td {
  word-break: break-all;
}

.px200 {
  overflow: hidden;
  max-width: 300px;
  word-wrap: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataTables_filter {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: .7rem;
}
</style>
