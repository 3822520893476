<script setup>
import {defineProps, useSlots, ref, onMounted} from 'vue'
import axios from 'axios';
import jsbeautify from 'js-beautify'

import 'highlight.js/styles/stackoverflow-dark.css'
import hljs from 'highlight.js/lib/common';

const options = { indent_size: 2, space_in_empty_paren: true }

const code = ref(null) 
const data = ref(null)

onMounted(() => {
  let code_string = jsbeautify(data.value.textContent, options)
  code.value = hljs.highlightAuto(code_string).value
})
</script>

<template>
  <div id="codepanel">
    <div ref="data" v-show="false">
      <slot></slot>
    </div>
    <div class="source-code" style="white-space:pre" v-html="code"></div>
  </div>
</template>

<style scoped>
.source-code {
  color:white;
}
</style>
