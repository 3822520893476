import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Feeds {
  async list() {
    let { data } = await axios.get(API_URL + '/feeds/list');
    return data;
  }

  async featured() {
    let { data } = await axios.get(API_URL + '/feeds/featured');
    return data;
  }

  async details(feed_id) {
    let { data } = await axios.get(API_URL + '/feeds/'+feed_id);
    return data;
  }

  async list_streams(feed_id) {
    let { data } = await axios.get(API_URL + '/feeds/'+feed_id+'/streams');
    return data;
  }
  async list_items(feed_id, offset) {
    let { data } = await axios.get(API_URL + '/feeds/'+feed_id+'/items/'+offset);
    return data;
  }

  async add_stream(feed_id, stream_id) {
    let { data } = await axios.get(API_URL + '/feeds/'+feed_id+'/add/'+stream_id);
    return data;
  }

  async create(feed_name, feed_description, feed_visibility, summary) {
    let { data } = await axios.post(API_URL + '/feeds/create', {
      name: feed_name,
      summary: summary,
      description: feed_description,
      visibility: parseInt(feed_visibility)
    });
    return data;
  }

  async update(feed_id, feed_name, feed_description, feed_visibility, summary) {
    let { data } = await axios.post(API_URL + '/feeds/update', {
      id: feed_id,
      name: feed_name,
      summary: summary,
      description: feed_description,
      visibility: parseInt(feed_visibility)
    });
    return data;
  }

  async delete(feed_id) {
    let { data } = await axios.post(API_URL + '/feeds/delete', {
      f_id: feed_id,
    });
    return data;
  }
}

export default new Feeds();
