<script setup>
import {defineProps} from 'vue'
const props = defineProps(["data", "maxlen"])

let items = {}

if (props.data != null) {
  for (let i=0; i<props.data.length; i++) {
    let parts = props.data[i].split("_")
    
    // its a main tag
    if (parts.length == 1) {
      if (parts[0] in items) {
        continue
      }

      items[parts[0]] = {
        main: parts[0],
        sub_items: [] 
      }
    // it is a subtag
    } else if (parts.length > 1) {
      let main_tag = parts[0]
      if (!(main_tag in items)) {
        items[main_tag] = {
          main: main_tag,
          sub_items: [] 
        }
      }
      items[main_tag].sub_items.push(
        {
          main: parts.join("_"),
          sub_items: [],
        }
      )
    }
  }
}

var shown_tags = [];
var hidden_tags = [];

if (props.maxlen != undefined && props.maxlen != null && Object.keys(items).length > 0) {
  let i = 0; 
  for (const [key, value] of Object.entries(items)) {
    if (i < props.maxlen) {
      shown_tags.push(value) 
    } else {
      if (value.sub_items.length > 0) {
        for (let i=0; i<value.sub_items.length; i++) {
          hidden_tags.push(value.sub_items[i])
        }
      } else {
        hidden_tags.push(value)
      }
    }
    i = i+1;
  }
} else {
  for (const [key, value] of Object.entries(items)) {
    shown_tags.push(value)
  }
}
</script>

<template>

  <div>
    <div v-if="props.data != null && props.data.length > 0" class="d-flex vertically-centered" >
      <div v-for="tag in shown_tags">
        <div v-if="tag.sub_items.length > 1" style="position: relative;" class="tag dropdown dropdown-toggle" data-bs-toggle="dropdown" >
          <span >{{tag.main}} +{{tag.sub_items.length}}</span>
          <ul class="dropdown-menu">
            <li v-for="st in tag.sub_items" >{{st.main}}</li>
          </ul>
        </div>
        <div v-else-if="tag.sub_items.length == 1" class="tag">
          <span >{{tag.sub_items[0].main}}</span>
        </div>
        <div v-else class="tag">
          <span >{{tag.main}}</span>
        </div>
      </div>
      <div v-if="hidden_tags.length > 0">
        <span class="tag dropdown dropdown-toggle" data-bs-toggle="dropdown" ><span>+ {{hidden_tags.length}}</span></span>
        <ul class="dropdown-menu scrollable">
          <li v-for="st in hidden_tags" >{{st.main}}</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<style scoped>
.dropdown-menu li {
  color:white  !important;;
  padding: 0 .5rem;
} 
.dropdown-menu {
  background-color: #151a22 !important;
}

.tag {
  position: relative;
  border: 1px solid #616B79;
  border-radius: 20px;
  font-size: .9rem;
  padding: 0 .5rem;
  margin-right: .1rem;
}
</style>
