<script setup>
import {defineProps, useSlots, ref, onMounted} from 'vue'

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

let slots = useSlots()

const props = defineProps({
  title: String,
  content: String,
  statuscode: Number,
  raw: {
    type: Boolean,
    default: false
  },
  pre: {
    type: Boolean,
    default: false
  }
});

let data = {}

if (props.raw) {
  data = slots.default()[0].children
} else {
  data = JSON.parse(slots.default()[0].children)
}
</script>

<template>
  <div class="block mb-3">
    <div class="title">
      <span v-if="statuscode >= 200 && statuscode < 300" class="method" style="color: #13ce66;">{{statuscode}}</span>
      <span v-if="statuscode >= 300 && statuscode < 400" class="method" style="color: rgb(231 242 92);">{{statuscode}}</span>
      <span v-if="statuscode >= 400 && statuscode < 500" class="method" style="color: rgb(242 92 92);">{{statuscode}}</span>
      {{ props.title }}
    </div>   
    <div class="content">
      <div v-if="raw">
        <pre v-if="pre">{{data}}</pre>
        <div v-else>{{data}}</div>
        
      </div>
      <vue-json-pretty v-else :data="data" style="overflow: auto; max-height: 600px;"/>
    </div>   
  </div>
</template>

<style scoped>
.block {
  border-radius: 10px;
  background-color:#1e252f;
}

.method {
  font-weight: normal;
  font-size: .8rem;
  margin-right: .5rem;
}

.block .title {
  background-color:#2a3340;
  border-radius: 10px 10px 0 0 ;
  padding: .3rem .8rem;
  color: white;
  font-weight: bold;
}

.block .content {
  padding: .8rem;
}

::-webkit-scrollbar-thumb {
  background-color: #2b3340;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
</style>
