<script setup>
import { onMounted, watch, computed, ref, defineProps } from 'vue'
const props = defineProps(['data'])
import Table from '@/components/Query/Table'
import moment from 'moment'

let columns = computed(() => {
  let columns = []
  if (props.data.data != null && props.data.data != undefined && props.data.data.length > 0) {
    for (const key of Object.keys(props.data.data[0])) {
      columns.push({ data: key, title: key, name: key })
    }
  }
  return columns
}) 
</script>

<template>
  <div class="p-4">
      <div class="mb-3">
        <h4>{{ props.data.title }}</h4>
      </div>

      <Table
         :result="props.data.data" 
          style="display: flex; height: 95%;"
        />
      <!--
      <div class="row mb-4">
        <div class="col-md-12">
          <div class="d-flex">
            <div class="box small-padded me-2 d-flex vertically-centered" style="border-radius: 10px;">
              <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'database']" />
              <div class="me-4">Items</div>
              <div class="white"><strong>{{details.ItemCount}}</strong></div>
            </div>

            <div class="box small-padded d-flex vertically-centered" style="border-radius: 10px;">
              <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'clock-rotate-left']" />
              <div class="me-4">Last update</div>
              <div class="white"><strong>{{ moment(details.LastUpdated).fromNow()}}</strong></div>
            </div>
          </div>
        </div>
      </div>
      -->
  </div>
</template>


<style scoped>
.button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .5rem 1rem;
}

.button:hover {
  background-color: #2B3440;
  cursor: pointer;
}
</style>
