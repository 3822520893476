import { defineStore } from 'pinia'
import { useUserStore} from './user'
const events = require('events')


//import General from '@/api/General.js'; 

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      cookieConsent: false,
      nav_resources: '',
      nav_plan: '',
      nav_account: '',
      nav_logo: '',
      nav_needhelp: '',
      
      slideover_show: false,
      slideover_component: null,
      slideover_props: null,

      modal_show: false,
      modal_component: null,
      modal_props: null,

      emitter: new events.EventEmitter()
    }
  },

  getters: {

  },

  actions: {
    async init() {
        let userStore = useUserStore()
        await userStore.init()
        /*
        if(validTo && Date.parse(validTo) > Date.now()) {
          // user cookie remains active
          // -- The user is already logged in --
          await userStore.init()
        } 
        */
        //this.countries = await General.countries();        
    },

    show_slideover(comp, props) {
      this.slideover_props = props
      this.slideover_component = comp
      this.slideover_show = true
    },

    close_slideover() {
      this.slideover_show = false
    },

    show_modal(comp, props) {
      this.modal_props = props
      this.modal_component = comp
      this.modal_show = true
    },

    close_modal() {
      this.modal_show = false
    },

    event_emit(key, data) {
      this.emitter.emit(key, data);
    },

    event_on(key, callback) {
      this.emitter.on(key, callback)
    },

    event_on_once(key, callback) {
      this.emitter.once(key, callback)
    }
  }
})
