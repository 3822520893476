<script setup>
import {defineProps, ref, onMounted, computed} from 'vue'

const props = defineProps(['item', 'data'])

const loading = ref(false)
const feeds = ref([]) 
const feed_hits = computed(() => {})

onMounted(async () => {
  loading.value = false;
  
  if (props.data != undefined && props.data != null) {
    feeds.value = props.data
  } else {
    // TODO: 
    // load the indicators from the API 
  }

  loading.value = true;
})
</script>

<template>
  <div class="streams">
    <h5>{{ props.item }}</h5>
    <hr>
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-3" v-for="stream in feeds">
          <div class="d-flex box small-padded vertically-centered">
            <span class="text-white ss small">{{stream.Name}}</span>
            <font-awesome-icon v-if="stream.Featured" class="ms-auto" style="font-size: 1.2rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
            <font-awesome-icon v-else class="ms-auto" style="font-size: 1.2rem; color: #82ca50;" :icon="['fas', 'circle-check']"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.streams {
  padding: 2rem;
}
.ss {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
