module.exports = {

    includeScript( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    
    is_domain(domain) {
        if (/^[a-zA-Z0-9-]{1,61}(?:\.[a-zA-Z]{2,})+$/.test(domain)) {
            return true;
        } else {
            return false;
        }
    },

    is_url(url_i) {
       try {
            let url = new URL(url_i);
            
            if ((url.protocol != "http:" && url.protocol != "https:") || !this.is_domain(url.hostname)) return false; 
            return true; 
       } catch (_) {
            return false;  
       }
    }
  
}
