<script setup>
import GuestNav from '../components/DashboardNav.vue';
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

</script>

<template>
    <div class="wrapper"> 
        <div class="wrapper-content"  style="flex-direction: column">
            <div>
                <guest-nav style="justify-content: center;"></guest-nav>
            </div>
            <router-view></router-view>
        </div>
    </div> <!-- end body --> 
</template>

<style scoped>
  
.wrapper {
  /*background-image: linear-gradient(15.57deg, rgba(39, 47, 59, 0) 27.85%, rgba(39, 47, 59, 0.22) 80.34%, rgba(39, 47, 59, 0.53) 89.67%, rgba(39, 47, 59, 0.77) 95.42%, #272F3B 99.67%), #191F27;*/
  /*background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);*/
  background-image: linear-gradient(to top, #191F27 0%, #272F3B 100%);
}
</style>
