import { defineStore } from 'pinia'

import Query from "@/api/Streams.js"
import { useUserStore } from "./user.js"

/*
  Install as Store as Pinia.

  Create an api js file in a separate folder with useAxios inside and define a function that fetches to your backend server.

  Define an action inside your Pinia store that calls the api method and sets the data inside the store.

  Create a computed inside your Component that returns the store value.

  Call the Action inside created with async await inside your Component.
*/

export const useStreamStore = defineStore('stream', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      stream_name: "",
      
      data: [],
      
      error: "",

      running: false,

      display_json: false,

      filters: [], 

      connection: null,

      count: 0,
    }
  },

  getters: {
    failed: (state) => {
      return state.error != ""
    },

    empty: (state) => {
      return state.data.length == 0
    },

    valid_query: (state) => {
      return state.stream_name != ""
    }
  },

  actions: {
    reset() {
      this.data = null; 
      this.error = "";
    },

    listen(id) {
      let user = useUserStore()

      try {
        this.connection = new WebSocket("ws://localhost:3010/streams/listen?id="+id+"&token="+user.token)

        this.connection.onmessage = ( event ) => {
          let items = JSON.parse(event.data)
          
          for (let i of items) {
            i.internal_generated_idx = this.count; 
            this.count += 1;
            this.data.push(i)
            console.log(i)
          }
        }

        this.connection.onopen = function() {
          //console.log(event)
        }

        this.running = true;
        console.log(this.data)

      } catch (error) {
        this.running = false
        this.error = error
      }
    },

    stop() {
      if (!this.running || this.running == undefined) return;
      this.connection.close()
      this.connection = null;
      this.data = [];
      this.running = false; 
    }
  },

})
