<script setup>
import {defineProps, watch, onMounted, ref} from 'vue'

const props = defineProps({
    "show": Boolean,
    "properties": Object,
    "component": Object,
    "options": Object
})

const default_options = {
  'width': 50,
}

const options = ref({...default_options})

onMounted(() => {
  if (props.options != undefined && props.options != null) {
    options.value = Object.assign(options.value, props.options)
  }
}) 

watch(() => props.options, (newval) => {
  if (newval == undefined || newval == null) {
    options.value = {...default_options}
    return
  }

  options.value = Object.assign(options.value, props.options)
})

</script>

<template>
  <div v-if="props.show" @click.self="$emit('slideover-close')" @keydown.esc="$emit('slideover-close')" id="overlay">
    <div id="slideover" :style="{ 'left': options.width + '%' }">
      <button type="button" @click="$emit('slideover-close')" class="btn-close btn-close-white" aria-label="Close"></button>
      <component v-if="props.component || props.component == {}" @slideover-close="$emit('slideover-close')" :is="props.component" v-bind="props.properties" id="component"/>
      <slot v-else />
    </div>
  </div>
</template>

<style scoped>
.btn-close {
  position: absolute; 
  right: 2rem;
  top: 2rem;
}

#overlay {
  background-color: #13182040;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

#slideover {
  background-color: #131820;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  /* left: 50%; */
  z-index: 100;
  box-shadow: 1px 0 6px 6px #0c1015;
  border-left: 1px solid #19212d;
}

#component {
  height: 100%;
  overflow: scroll;
}
</style>
