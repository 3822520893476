<script setup>
import { defineProps, ref } from 'vue'
import UserApi from '@/api/User.js'

const emit = defineEmits(['modal-close'])

const is_submitting = ref(false)
const is_sent = ref(false)
const failed = ref(false)

const props = defineProps({
  sender: { type: String },
  message: { type: Array, default: [] },
  product: { type: String, default: ""}
})

async function send() {
  is_submitting.value = true

  let res = await UserApi.contact( props.sender, props.product, props.message)

  if (res.status == "failed") {
    failed.value = true
  }

  is_submitting.value = false
  is_sent.value = true
}
</script>

<template>
  <div v-if="failed">
    <div>
      <div class="text-center mb-4">
         <font-awesome-icon style="font-size: 5rem; color: #ca5050;" :icon="['fas', 'circle-exclamation']"/>
      </div>

      <h3 class="title mb-4 text-center">Failed</h3>
      <div class="text-center d-flex column centered">
        <p style="max-width: 700px;">Sorry! Something went wrong. Please try again later, or send an email directly to <a href="mailto:help@webscout.io"><strong class="white">help@webscout.io</strong></a> with your question</p>
      </div>
    </div>
  </div>
  <div v-else-if="!is_sent">
    <div class="title white mb-3">
      Contact 
    </div>

    <div class="menu-container">

      <form class="form search_form form-floating" id="f" @submit.prevent="send" style="flex-grow: 1">
          <!--<input class="search_input form-control" id="floating" ref="i" v-model="search_input" placeholder="Query (CTRL+K)">-->
        <div class="form-floating mb-2">
            <input type="text" v-model="props.sender" disabled class="form-control" id="floatingInput" >
            <label for="floatingInput">sender</label>
        </div>

        <div class="form-floating mb-2">
            <input type="text" v-model="props.product" disabled class="form-control" id="floatingInput" >
            <label for="floatingInput">product</label>
        </div>

        <div class="form-floating mb-2">
          <textarea class="form-control" v-model="props.message" id="floatingPassword" placeholder="Password" style="height: 300px;" resi></textarea>
          <label for="floatingInput">message</label>
        </div>
        
        <div class="d-flex centered">
          <button class="button" type="submit" form="f" v-if="!is_submitting">Send</button>
          <button class="button" v-else :class="{'disabled': is_submitting}" >Sending...</button>
        </div>
      </form>
    </div>
  </div>
  <Transition v-else>
    <div>
      <div class="text-center mb-4">
        <img src="~@/assets/icons/check-circle-filled.svg" width="100" height="100" alt="">
      </div>

      <h3 class="title mb-4 text-center">Sent!</h3>
      <div class="text-center d-flex column centered">
        <p style="max-width: 700px;">We are happy to from you. Within the next working day, one of our team members will get back to you with information on how to proceede.</p>
        <p>Thank you!</p>
      </div>
    </div>
  </Transition>

</template>

<style scoped>
.title {
  font-size: 2rem; 
}

.option-title {
  display: flex;
  background-color: #151a22;
  color: white;
  padding: 1rem;
}

.option {
  background-color: #272F3B;
  padding: 1rem;
  display: flex;
  border-left: 1px solid  #151a22;
  border-right: 1px solid  #151a22;
  border-bottom: 1px solid  #151a22;
  color: white;
}

.option:hover {
  background-color: #363f4d;
  cursor: pointer;
}

.form-control:disabled {
  background-color: #1F2631;
  color: #ffffffba
}

.button {
  background-color: #1E252F;
  border-radius: 10px;
  border:none;
  font-weight: bold;
  color: white;
  padding: .5rem 2rem;
}

.button.dark {
  background-color: #131820
}

.button:hover {
  background-color: #2B3440;
}

.v-enter-active {
  transition: opacity 1s ease;
}
.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
