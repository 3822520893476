<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

import { useStreamStore } from '@/stores/stream'
let streamStore = useStreamStore()

const i = ref(null)
const search_input = ref("")

const r_defang = /[\(\)\[\]{}<>\\]/g;

function defang(item) {
  let str = item.replaceAll(r_defang, "")
  str = str.replaceAll("hxxp", "http")
  return str
}

watch(() => queryStore.query_string, async (newval) => {
  search_input.value = newval
  //await search(search_input.value)
})

async function search(query) {
  query = defang(query)
  
  if (valid_domain(query)) {
    query = "domain = " + query
  } else if (valid_ip(query)) {
    query = "ip = " + query
  } else if (valid_stream(query)) {
    search_input = query
    streamStore.listen()
    router.push({
      name: "UserStream",
    })

    return 
  }
  
  await queryStore.query(query)

  if (query == "") return 

  router.push({
    name: "UserQuery",
  })
  /*
  if (valid_ip(query)) {
    router.push("/query/ip/"+query, params: )
  }
  else if (valid_domain(query)) {
    router.push("/query/domain/"+query)
  }
  else if (valid_jarm(query)) {
    router.push("/query/jarm/"+query)
  }
  // this currently always return true.
  else {
    if (query.includes("/") && !query.startsWith("http")) {
      query = "http://" + query
    }
    router.push("/query/url/"+btoa(query))
  }
  */
}

function valid_stream(query) {
  let str = query.replaceAll(" ", "");
  return str.startsWith("stream=");
}


function valid_ip(value) {
  return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\/(3[0-2]|2[0-9]|1[0-9]|[0-9]\b))?$/.test(value) || 
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/.test(value)
}

function valid_domain(value) {
  return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z]$/.test(value)
}

function valid_url(value) {
  return true;
  return /^(?:(http(s)?|ftp):\/\/)([a-zA-Z0-9\-]{1,256}\.)+([a-zA-Z0-9]*){2,6}$/.test(value) ||
  /^(?:(http(s)?|ftp):\/\/)([a-zA-Z0-9]{1,256}\.)+([a-zA-Z0-9]*){2,10}(\/[\w.+\-@\?=]*)+$/.test(value)
}

function valid_jarm(value) {
  return /^(\w){62}$/.test(value)
}

function handle_keydown(event) {
  if (event.ctrlKey && event.key === 'k') {
    event.preventDefault();
    i.value.focus()
  }
}

onMounted( () => {
  document.addEventListener('keydown', handle_keydown);
})

onUnmounted( () => {
  document.removeEventListener('keydown', handle_keydown)
})
</script>

<template>
  <div class="">
    <div class="d-flex" style="position: sticky; top:0; z-index: 1; background-color: #151a22;">
      <form class="form search_form form-floating" @submit.prevent="search(search_input)" style="flex-grow: 1">
          <!--<input class="search_input form-control" id="floating" ref="i" v-model="search_input" placeholder="Query (CTRL+K)">-->
        <div class="form-floating ">
            <input type="text" autocomplete="off" ref="i" v-model="search_input" class="form-control" id="floatingInput" placeholder="name@example.com">
            <label for="floatingInput">query</label>
        </div>

      </form>
      <div class="d-flex vertically-centered" style="padding: 0 1rem;">
        <!--
        <button v-if="queryStore.display_json == false" class="" @click="queryStore.display_json = !queryStore.display_json">display json</button>
        <button v-else class="" @click="queryStore.display_json = !queryStore.display_json">display table</button>
        -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.search_form {
  background-color: #151a22;
}

.search_selector {
  cursor: pointer;
  transition: color .4s ease; 
  border: none; 
  outline: none;
  color: rgb(109, 119, 133);
}

.search_selector:hover {
  color: white;
}

.search_input {
  width: 100%;
  display: block;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  padding: .5rem 1rem;
}

button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .5rem 1rem;
}

button:hover {
  background-color: #2B3440;
}

input {
  background-color: transparent !important;
}

form {
  padding: 0rem;
  padding-left: 1rem;
}



.form-floating label {
  padding-left: 0px;
}
.form-floating input {
  padding-left: .2rem;
}

</style>

