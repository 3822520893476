<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import Auth from '../api/Auth.js'
const router = useRouter()

// components 
let name = ref('')
let email = ref('');
let password = ref('');
let status = ref('');
let isSubmitting = ref(false);
let tos = ref(false)
let success = ref(false)
let message = ref("")

onMounted(async () => {
  
})

async function signUp() {
        isSubmitting.value = true
        // const { data } = await axios.get(context.env.apiUrl + "/api/services"); 
        // should send a verification email ---- 2FA??
        // let {data} = await Auth.register(this.email, this.password)

        let data  = await Auth.register(email.value, password.value, name.value)


        isSubmitting.value = false
        
        if(data.status) {
            email.value = ''
            password.value = ''
            success.value = true;            
        } else {
            message.value = data.message
        }
}

async function resendVerification() {
    console.log("TODO: Resending email...")
}

</script>



<template>
    <div class="wrapper">
        
        <div class="container fill align-items-center">

            <div class="col-md-6">

                <div v-if="!success" id="login-container">
                    <h3 class="subheader mb-4">Get started for free</h3>
                    <h1 class="display-4 header mb-4">Create an account</h1>
                    <p class="subsubheader " :class="{ 'mb-5': !status, 'mb-3': status }">Already have an account? <router-link to="/login" class="ws-gradient-text"><strong>Login</strong></router-link> </p>
                    <p v-if="!success" class="ms-1 danger mb-4">{{message}}</p>
                    <form action="#" @submit.prevent="signUp()" class="form-floating mb-4">
                        <div class="form-floating mb-4 col-md-7">
                            <input type="text" v-model="name" class="form-control dark" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Username</label>
                        </div>
                        <div class="form-floating mb-4 col-md-7">
                            <input type="email" v-model="email" class="form-control dark" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Email address</label>
                        </div>
                        <div class="form-floating mb-4 col-md-7">
                            <input type="password" v-model="password" class="form-control dark" id="floatingPassword" placeholder="Password">
                            <label for="floatingPassword">Password</label>
                        </div>
                        <div class="mb-5 form-check ms-1">
                            <input type="checkbox" v-model="tos" class="form-check-input dark" id="exampleCheck1">
                            <label class="form-check-label"  for="exampleCheck1">I have read, understand, and accept the <router-link to="/resources/termsofservice">Terms of Service</router-link></label>
                        </div>
                        <button id="submit-btn" v-if="!isSubmitting" :disabled="!tos || !email || !password" type="submit" class="btn btn-primary ms-2">Sign up</button>
                        <button id="submit-btn" v-else disabled type="submit" class="btn btn-primary ms-2">Creating account...</button>
                    </form>
                </div>
                <div v-else id="login-container">
                    <h3 class="subheader mb-4">One step away</h3>
                    <h1 class="display-4 header mb-4">Account created!</h1>
                    <p class="subsubheader mb-5" style="color:white;">We've sent you a verification email. Use the link in the email to verify your account and login</p>
                    <p class="subsubheader">Haven't received an email? <span @click="resendVerification()" class="resend">Click here to resend it</span></p>
                </div>

            </div>

        </div>


    </div>

</template>

<style scoped>

.wrapper {
    background-image: url('~@/assets/split-map.svg');
    background-repeat: no-repeat;
    background-position: 0;
    background-position-x: 100%;
    background-size: 55%;
}

.danger {
    color: #ffb0b0;
    font-size: 1rem;
}

.header {
    font-weight: 900;
}

.subheader {
    font-weight: 500;
    color: #536173;
    font-family: Sansation;
}

.subsubheader {
    color: #536173;
    font-size: 1.1rem;
    font-family: Sansation; 
}

#submit-btn {
    padding: .8rem 5rem; 
    background-color:#7357D2;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    border-radius: 30px;
}

#submit-btn:hover {
    background-color: #7F62E3;
}

.resend {
    font-weight: 500;
    cursor: pointer;
}

.resend:hover {
    color:white;
}

</style>


