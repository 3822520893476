<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_hash = ref("21109_2_1460_0")
let request_type = ref(202)

let get_query = computed(() => {
  return "/query/hash/" + request_hash.value + "/" + request_type.value 
}) 

</script>

<template>
  <div>
    <doc-section title="Hash">
      <template v-slot:left>
        <div class="mb-5">
          <div class="mb-3">
            The hash endpoint allows for retrieving ip addresses associated with a given hash or fingerprint. The output is tightly coupled with the type of hash you look up. A table of the supported hash types, and the their output types can be seen below.</div>

          <div style="max-height: 600px; overflow-y: scroll;">
            <table class="table">
              <thead>
                <tr>
                  <th class="first">#</th>
                  <th class="second">Hash/fingerprint</th>
                  <th>description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">100</th>
                  <td>JA3S</td>
                  <td>Returns IP addresses that responded with this JA3s fingerprint. Useful for identifying malicious servers.</td>
                </tr>
                <tr>
                  <th scope="row">101</th>
                  <td>JA3 Inbound</td>
                  <td>Returns responding IP addresses where this ja3 hash was observed from one or more connecting client IPs. Useful for identifying victims of malicious traffic.</td>
                </tr>
                <tr>
                  <th scope="row">200</th>
                  <td>JA4</td>
                  <td>Returns client IP addresses where this ja4 hash was observed connecting to one or more responding IPs. Useful for profiling and understanding clients.</td>
                </tr>
                <tr>
                  <th scope="row">201</th>
                  <td>JA4SSH</td>
                  <td>Returns client IP addresses where this ja4ssh hash was observed connecting to one or more responding IPs over SSH. Useful for identifying malicious SSH clients.</td>
                </tr>
                <tr>
                  <th scope="row">202</th>
                  <td>JA4T</td>
                  <td>Returns client IP addresses where this ja4t hash was observed connecting to one or more responding IPs. Useful for identifying malicious clients based on their TCP fingerprint.</td>
                </tr>
                <tr>
                  <th scope="row">203</th>
                  <td>JA4H</td>
                  <td>Returns client IP addresses where this ja4h hash was observed making HTTP requests to one or more responding IPs. Useful for identifying malicious HTTP clients.</td>
                </tr>
                <tr>
                  <th scope="row">204</th>
                  <td>JA4 Inbound</td>
                  <td>Returns responding IP addresses where this ja4 hash was observed from one or more connecting client IPs. Useful for identifying victims of malicious traffic.</td>
                </tr>
                <tr>
                  <th scope="row">205</th>
                  <td>JA4H Inbound</td>
                  <td>Returns responding IP addresses where this ja4h hash was observed from one or more connecting client IPs. Useful for identifying victims of malicious traffic.</td>
                </tr>
                <tr>
                  <th scope="row">206</th>
                  <td>JA4LS</td>
                  <td>Returns latency measurements associated with the ja4l server hash from one or more connecting client IPs. Useful for detecting anomalies based on network latency, such as impossibly fast connections which could suggest spoofed or proxied traffic.</td>
                </tr>
                <tr>
                  <th scope="row">207</th>
                  <td>JA4s</td>
                  <td>Returns responding IP addresses where this ja4s hash was observed communicating with one or more client IPs. Useful for identifying malicious servers.</td>
                </tr>
                <tr>
                  <th scope="row">208</th>
                  <td>JA4SSH Inbound</td>
                  <td>Returns responding IP addresses where this ja4ssh hash was observed from one or more connecting client IPs. Useful for identifying victims of malicious traffic.</td>
                </tr>
                <tr>
                  <th scope="row">209</th>
                  <td>JA4TS</td>
                  <td>Returns responding IP addresses where this ja4ts hash was observed communicating with one or more client IPs. Useful for identifying malicious servers based on their TCP fingerprint.</td>
                </tr>
                <tr>
                  <th scope="row">500</th>
                  <td>Jarm</td>
                  <td>Returns responding IP addresses where this JARM hash was observed communicating with one or more client IPs. Useful for identifying and grouping malicious servers on the Internet.</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>
          <StringParam class="mb-3" name="Hash" :required="true" description="The hash you wish to lookup" type="string" :value="request_hash" @value="(v) => request_hash = v" />

          <StringParam class="mb-3" name="Type Id" :required="true" description="The hash type (reference table above)" type="integer" :value="request_type" @value="(v) => request_type = v" />

          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array" description="The requested information">

            <BasicAttribute name="ip" type="string" description="The target IP address" />
            <hr>

            <BasicAttribute name="location" type="object" description="The location of the ip">
              <BasicAttribute name="country_iso" type="string" description="Two letter country code" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="domains_on_ip" type="integer" description="The number of domains resolving to the IP" />
            <hr>

            <BasicAttribute name="operator" type="string" description="The entity using the ip or machine behind it" />
            <hr>

            <BasicAttribute name="network_type" type="string" description="The likely type of infrastructure used to operate the ip" />
            <hr>

            <BasicAttribute name="network_provider" type="string" description="The entity managing the ip address" />
            <hr>

            <BasicAttribute name="network_provider_services" type="string array" description="A list of services the managing entity provides" />
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity assosiated with the IP address" />
            <hr>

            <BasicAttribute name="open_ports" type="integer array" description="Open network ports on the IP. This can be used to assertain it's use" />
            <hr>

            <BasicAttribute name="as_org" type="string" description="The name of the organization owning the ip address allocation" />
            <hr>

            <BasicAttribute name="asn" type="integer array" description="The AS number(s) of the organization owning the ip address allocation" />
            <hr>

            <BasicAttribute name="avg_ttl" type="integer" description="Average time-to-live (ttl) of packets coming from the IP address" />
            <hr>

            <BasicAttribute name="operator_description" type="string" description="A brief description of who the operator is" />
            <hr>

            <BasicAttribute name="provider_description" type="string" description="A brief description of who the network provider is" />
            <hr>

            <BasicAttribute name="os_guess" type="string" description="A guess on the operating sytem of the machine handling traffic going to the IP" />
            <hr>

            <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100" />

          </BasicAttribute>

        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/hash/:hash/:type" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [
{
"ip": "207.90.244.14",
"location": {
"country_iso": "US"
},
"domains_on_ip": 0,
"operator": "",
"network_type": "Cloud",
"network_provider": "SHODAN",
"network_provider_services": [
"Cloud",
"Security"
],
"behavior": [
"bruteforce",
"bruteforce_login",
"bruteforce_telnet",
"crime",
"malicious",
"probing",
"probing_http",
"probing_https",
"probing_kafka",
"probing_ldap",
"probing_mysql",
"probing_rdp",
"probing_rpc",
"probing_smb",
"probing_telnet",
"probing_vnc",
"scanning",
"scanning_dns_tcp",
"scanning_http",
"scanning_https",
"scanning_smtp",
"scanning_ssh",
"scanning_vnc"
],
"open_ports": null,
"as_org": "SHODAN, LLC",
"asn": [
174
],
"avg_ttl": 0,
"provider_description": "A search engine for Internet-connected devices, showcasing various web services and security aspects.",
"operator_description": "This IP address has been observed in unauthorized connections or connection attempts against Webscout's sensor net",
"os_guess": "",
"risk_score": 100
}
]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
  
.table {
  width: 100%;
  table-layout: fixed;
}


.table th {
  color: white;
  font-weight: normal
}

.table tr {
  border-bottom: 1px solid #29323E;
}

.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.table td {
  color: #6D7785;
}

.table .first {
  width: 3.5rem;
}

.table .second {
  width: 10rem;
}
</style>
